<template>
  <div>
    <div class="tabs">
      <b-link class="options" :class="active == 0 ? 'active' : ''" @click="changeTabs(0)">
        账号密码登录
      </b-link>
      <b-link class="options" :class="active == 1 ? 'active' : ''" @click="changeTabs(1)">
        手机快捷登录
      </b-link>
    </div>
    <div class="form">
      <div class="current" v-show="active == 0">
        <div class="input">
          <b-form-input type="text" placeholder="账号" v-model="account"></b-form-input>
        </div>
        <div class="input">
          <b-form-input
            type="password"
            placeholder="密码"
            v-model="password"
          ></b-form-input>
        </div>
        <div class="input" style="display: flex">
          <b-form-checkbox style="width: 60%" v-model="check"
            ><span style="margin-left: 10px">记住密码</span></b-form-checkbox
          >
          <b-link class="link" style="float: right; width: 40%; text-align: right"
            >忘记密码</b-link
          >
        </div>
      </div>
      <div class="current" v-show="active == 1">
        <div class="input">
          <b-form-input
            type="text"
            placeholder="请输入手机号码"
            maxlength="11"
            v-model="phone"
          ></b-form-input>
        </div>
        <div class="input" style="display: flex">
          <b-form-input
            type="text"
            placeholder="请输入手机号码"
            maxlength="4"
            v-model="code"
            style="width: 60%"
          ></b-form-input>
          <b-button
            variant="warning"
            style="width: 35%; margin-left: 5%; background: #ff984e; color: #fff"
            @click="sendCode"
            :disabled="time > 0"
            >{{ time == 0 ? "获取验证码" : time + "s" }}</b-button
          >
        </div>
      </div>
      <div class="submit">
        <b-form-checkbox v-model="accept"
          ><span style="margin-left: 10px"
            >我已阅读并接受《用户协议》、《隐私政策》</span
          ></b-form-checkbox
        >
        <b-button
          variant="warning"
          style="background: #ff984e; color: #fff; width: 100%; margin: 20px 0"
          @click="login"
          :disabled="loading"
          ><b-spinner v-show="loading" small style="margin-right: 7.5px"></b-spinner
          >登录</b-button
        >
        <div class="line">
          <b-icon icon="star-fill" style="color: #ff984e"></b-icon>
          未满十八岁用户登录需征得监护人同意
        </div>
        <div class="line">
          <b-icon icon="star-fill" variant="secondary"></b-icon>
          未注册过的手机号将自动创建为美蜂账号
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const testReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
export default {
  data() {
    return {
      active: 0,
      check: false,
      accept: false,
      account: "",
      password: "",
      phone: "",
      code: "",
      time: 0,
      loading: false,
    };
  },
  methods: {
    changeTabs(status) {
      this.active = status;
    },

    login() {
      console.log(this.accept);
      if (!this.accept)
        return this.$store.commit("showToast", "请同意用户协议及隐私政策");
      this.loading = true;
      if (this.active == 1) {
        this.mobileLogin();
        return;
      }
      let data = {
        account: this.account,
        password: this.password,
        code: "",
      };
      this.$request
        .login(data)
        .then((res) => {
          this.loading = false;
          let { data, code, msg } = res;
          if (code == 1) {
            this.$cache.setCache("doctorInfo", JSON.stringify(data.userinfo));
            this.$store.commit("showToast", msg);
            setTimeout(() => {
              this.$router.push({
                path: "/doctorInfo",
              });
            }, 2000);
          } else {
            this.$store.commit("showToast", msg);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    mobileLogin() {
      if (!this.accept)
        return this.$store.commit("showToast", "请同意用户协议及隐私政策");
      let data = {
        mobile: this.phone,
        captcha: this.code,
        code: "",
      };
      this.$request
        .mobileLogin(data)
        .then((res) => {
          this.loading = false;
          let { data, code, msg } = res;
          if (code == 1) {
            this.$cache.setCache("doctorInfo", JSON.stringify(data.userinfo));
            this.$store.commit("showToast", "登录成功");
            setTimeout(() => {
              this.$router.push({
                path: "/doctorInfo",
              });
            }, 2000);
          } else {
            this.$store.commit("showToast", msg);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    sendCode() {
      if (!testReg.test(this.phone))
        return this.$store.commit("showToast", "请输入正确的手机号码格式");
      let data = {
        mobile: this.phone,
        event: "mobilelogin",
      };
      this.$request.sendCode(data).then((res) => {
        let { code, msg } = res;
        if (code == 1) {
          this.$store.commit("showToast", "发送成功");
          this.time = 60;
          setInterval(() => {
            if (this.time > 0) return this.time--;
          }, 1000);
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "../login/login.scss";
.env {
  position: absolute;
  top: 100;
  width: 100px;
  height: 40px;
  background: #fff;
  color: #000;
  z-index: 999;
}
</style>
